<template>
  <div v-if="!isPageVisible">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>Resource Not Available</h3>
    </b-card>
  </div>
  <div v-else-if="user_type === 'agent'">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>"Previledge access only to Aliff Franchise."</h3>
    </b-card>
  </div>
  <b-card v-else style="background-color: #fcfcfc">
    <!-- <b-tabs content-class="mt-3">
      <b-tab title="Industry News" active>
        <div v-if="industry_news.length > 0">
          <b-card class="border p-2" v-for="(news, index) in blogs" :key="'blogs' + index"
            style="background-color: white">
            <h3>{{ news.bg_title }}</h3>
            <a :href="`${WEBSITE_URL}/aliff-blogs-recent/bg/${news.bg_id}/${news.bg_title.replace(/ /g, '-').toLowerCase()}`"
              target="_blank" rel="noopener noreferrer" class="mt-2" style="
      display: block;
      border-radius: 1rem;
      padding: 2%;
      border-color: #000;
      border-width: 1px;
      border-style: solid;
      text-decoration: none;
      color: inherit;
    ">
              Visit Blog: {{ WEBSITE_URL }}/aliff-blogs-recent/bg/{{ news.bg_id }}/{{ news.bg_title.replace(/ /g,
                '-').toLowerCase() }}
            </a>

          </b-card>
        </div>
        <template v-else>
          <h4 class="pl-2">No Available Industry News.</h4>
        </template>
</b-tab>
<b-tab title="Material">
  <div v-if="materials.length > 0">
    <b-card class="border p-2" v-for="(material, index) in materials" :key="'materials_' + index"
      style="background-color: white">
      <h3>{{ material.fm_name }}</h3>
      <div v-html="material.fm_text" class="mt-2" style="
                border-radius: 1rem;
                padding: 2% 2% 2% 2%;
                border-color: #000;
                border-width: 1px;
                border-style: solid;
              "></div>
    </b-card>
  </div>
  <template v-else>
          <h4 class="pl-2">No Available Material.</h4>
        </template>
</b-tab>
<b-tab title="Visa Fact Sheet">
  <div v-if="materials.length > 0">
    <b-card class="border p-2" v-for="(material, index) in fact_sheets" :key="'materials_' + index"
      style="background-color: white">
      <h3>{{ material.country_name }}</h3>
      <a :href="material.country_fact_sheet" target="_blank" rel="noopener noreferrer" class="mt-2" style="
      display: block;
      border-radius: 1rem;
      padding: 2%;
      border-color: #000;
      border-width: 1px;
      border-style: solid;
      text-decoration: none;
      color: rgb(17, 85, 204);

    ">
        {{ material.country_fact_sheet }}
      </a>

    </b-card>
  </div>
  <template v-else>
          <h4 class="pl-2">No Available Fact Sheets.</h4>
        </template>
</b-tab>
</b-tabs> -->
    <b-card>
      <b-form-input placeholder="Search..." v-model="search" @input="onSearch" />
    </b-card>
    <b-card class="bg-white p-2">
      <div class="row row-cols-3">
        <div v-for="(utility, t_index) in utilities" :key="'utility_' + t_index" class="mt-1 col w-100"
          style="cursor: pointer" @click="goToSingleUtility(utility)">
          <div class="h-100">
            <div class="col h-100 p-1">
              <article class="postcard dark red h-100">
                <a class="postcard__img_link overflow-hidden" href="#">
                  <div class="overflow-hidden w-100">
                    <img class="postcard__img img-fluid" :src="FILESURL + utility.ut_image" alt="Image Title" />
                  </div>
                </a>
                <div class="postcard__text">
                  <h1 class="postcard__title red">
                    <span class="text-dark">{{ utility.ut_name }}</span>
                  </h1>
                  <div class="postcard__bar"></div>
                  <div class="postcard__preview-txt text-dark">
                    {{ utility.ut_description }}
                  </div>
                  <div class="videos-in-course d-flex align-items-center" style="gap: 10px">
                    <div v-for="(item, index) in utility.franchise_material" :key="index"
                      v-if="index < 3 && utility.franchise_material.length > 0">
                      <ul class="postcard__tagbox justify-content-start">
                        <li class="tag__item">
                          {{ item.fm_name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import {
  BCard, BTab, BTabs, BCardText,
  BInputGroup,
  BFormInput,
} from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import CommonServices from "@/apiServices/CommonServices";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { WEBSITE_URL, FILESURL } from "@/config";

export default {
  components: {
    BCard, AppCollapse, AppCollapseItem, BTab, BTabs, BCardText,
    BInputGroup,
    BFormInput,
  },
  data() {
    return {
      collaterals: [],
      industry_news: [],
      blogs: [],
      materials: [],
      user_type: null,
      fact_sheets: null,
      utilities: [],
      WEBSITE_URL,
      FILESURL,
      search: '',
      debounce: null
    };
  },
  computed: {
    isPageVisible() {
      const user_details = this.$store.getters["user/getUserDetails"];
      this.user_type = user_details.user_type;
      if (user_details && Array.isArray(user_details.allocated_resources)) {
        const allowcatedResource = user_details.allocated_resources.find(
          (elem) => elem === "marketing_insight"
        );
        if (allowcatedResource) {
          if (this.user_type === 'franchise' || this.user_type === 'agent') {
            this.getFranchiseIndustryNews()
            this.getFranchiseMaterial()
          } else {
            this.getCounselorNews()
            this.getAllMaterial()
          }
          return true;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    async goToSingleUtility(ut) {
      this.$router.push({
        name: "Utility Details",
        params: { ut_id: ut.ut_id },
      });
    },
    async getFranchiseMaterial() {
      try {
        const response = await AgentServices.getFranchiseMaterial();
        this.materials = response.data.data;
      } catch (error) {
        console.error(`Error in getFranchiseMaterial`);
      }
    },
    async getAllBlogs() {
      try {
        const response = await CommonServices.getAllBlogs();
        this.blogs = response.data.data;
      } catch (error) {
        console.error(`Error in getAllBlogs`);
      }
    },
    async getFranchiseIndustryNews() {
      try {
        const response = await AgentServices.getFranchiseIndustryNews();
        this.industry_news = response.data.data;
      } catch (error) {
        console.error(`Error in getFranchiseIndustryNews`);
      }
    },
    async getCounselorNews() {
      try {
        const response = await CommonServices.getAllIndustryNews();
        this.industry_news = response.data.data;
      } catch (error) {
        console.error(`Error in getFranchiseIndustryNews`);
      }
    },
    async getAllMaterial() {
      try {
        const response = await CommonServices.getAllMaterial();
        this.materials = response.data.data;
      } catch (error) {
        console.error(`Error in getFranchiseMaterial`);
      }
    },
    async getCountryFactSheet() {
      try {
        const response = await CommonServices.getCountryFactSheet();
        this.fact_sheets = response.data.data;
      } catch (error) {
        console.error(`Error in getCountryFactSheet`);
      }
    },
    async getAllUtilities() {
      try {
        const response = await CommonServices.getAllUtilities({
    search : this.search
        })
        if (response.data.status) {
          this.utilities = response.data.data
        }
      } catch (error) {
        console.error(`Error in getAllUtilities`, error);
      }
    },
    onSearch() {
      if (this.debounce) {
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onFilterChange();
      }, 1000);
    },
    onFilterChange() {
      this.$nextTick(() => {
        this.getAllUtilities();
      });
    },
  },
  beforeMount() {
    this.getAllBlogs()
    this.getCountryFactSheet()
    this.getAllUtilities()
  },
};
</script>

<style>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.min-height {
  min-height: 60vh;
}
</style>
